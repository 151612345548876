import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import { ITaggedModel } from '../../types/tags';
import { Observable, distinctUntilChanged, lastValueFrom, map, of, startWith } from 'rxjs';
import {COMMA, ENTER, SEMICOLON} from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { faTimes, faTimesCircle } from '@fortawesome/pro-light-svg-icons';
import { FormControl } from '@angular/forms';

export interface ModelTagsUpdateHandler {
  setTags(tags: string[]): Promise<any>;
}

export interface ModelTagsService {
  getAllTags(): Observable<string[]>;
  setTags(id: number, tags: string[]): Promise<any>;
}

@Component({
  selector: 'app-model-tag-modal',
  templateUrl: './model-tag-modal.component.html',
  styleUrls: ['./model-tag-modal.component.scss']
})
export class ModelTagModalComponent implements OnInit {

  @Input() model: ITaggedModel;
  @Input() modelTagsUpdateHandler: ModelTagsUpdateHandler | undefined;
  @Input() modelTagsService: ModelTagsService;
  @Input() modalRef: NgbModalRef;

  @ViewChild('tagInput', {static: true}) tagInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto', {static: true}) autoComplete: MatAutocomplete;

  public tags: string[];
  public allTags: string[] = [];

  tagCtrl = new FormControl('');
  filteredTags: Observable<string[]>;

  separatorKeysCodes: number[] = [ENTER, COMMA, SEMICOLON];

  removeIcon = faTimes; //  faTimesCircle;

  constructor() {
  }

  ngOnInit() {
    if (!this.modelTagsService) {
      throw Error("modelTagsService should be passed")
    }

    this.filteredTags = this.tagCtrl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || ''))
    );

    lastValueFrom(this.modelTagsService.getAllTags()).then(all => {
      this.allTags = all
      this.filteredTags = of(Object.assign([], this.allTags))
    });
    this.tags = Object.assign([], this.model.tags);
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.allTags.filter(tag => tag.toLowerCase().includes(filterValue));
  }

  add(event: MatChipInputEvent) {
    const tag = (event.value || '').trim();
    const index = this.tags.indexOf(tag);
    if (index < 0) {
      this.tags.push(tag);
      this.tagInput.nativeElement.value = '';
      this.tagCtrl.setValue('')
      this.autoComplete.showPanel = false;
    }
  }

  remove(tag: string) {
    const index = this.tags.indexOf(tag);
    if (index >= 0) {
      this.tags.splice(index, 1);
    }
  }

  paste(event: ClipboardEvent): void {
    event.preventDefault();
    const splits = event.clipboardData.getData('Text').split(/;|,|\n/).map(v => v.trim()).filter((v, i) => v.length > 0 && splits.indexOf(v, i + 1) < 0);
    this.tags.push(...splits);
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.tags.push(event.option.viewValue);
    this.tagInput.nativeElement.value = '';
    this.tagCtrl.setValue('')
    // this.tagCtrl.setValue(null);
  }

  async doSave() {
    if (this.modelTagsUpdateHandler) {
      await this.modelTagsUpdateHandler.setTags(this.tags);
    } else {
      this.model.tags = this.tags;
      await this.modelTagsService.setTags(this.model.id, this.tags);
    }
    this.modalRef.close();
  }

  doCancel() {
    this.modalRef.dismiss();
  }

  // @HostListener('click')
  // onClick() {
  //   if (!this.tagInput.isInputFocused() && this.tagInputDropdown.isVisible) {
  //     this.tagInputDropdown.hide();
  //   }
  // }

}
