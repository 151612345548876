import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {ILens, LensActions} from '../types/lens';
import {LensService} from '../services-http/lens.service';
import {ActivatedRoute, Event, Router, RouterEvent} from '@angular/router';
import {delay, filter, first, map, takeUntil} from 'rxjs/operators';
import {IModelProcessingDisplayStatus, ModelProcessingStateManager} from './modelProcessingStateManager';

@Component({
  selector: 'app-lens-loader',
  templateUrl: './lens-loader.component.html',
  styleUrls: ['./lens-loader.component.scss']
})
export class LensLoaderComponent implements OnInit, OnDestroy {

  private _interval: number;

  @Input() public lens$: Observable<ILens>; // uaData source
  public lensId: number = -1;
  public LensActions = LensActions;

  public displayState$: Observable<IModelProcessingDisplayStatus>;
  public parentLensId: number;
  private _modelProcessingProgress: ModelProcessingStateManager = new ModelProcessingStateManager();

  constructor(private lensService: LensService,
              private router: Router,
              private activeRouter: ActivatedRoute) {
  }

  ngOnInit() {
    this._modelProcessingProgress.reset();
    this.lensService.clear();
    this.activeRouter.params.pipe(
      takeUntil(this.router.events.pipe(
        filter((event: Event | RouterEvent) => event instanceof RouterEvent)
      ))
    ).subscribe(params => {
      this.lensId = parseInt(params['id']);
      this.lens$ = this.lensService.element.pipe(filter(lens => (!!lens)));

      this.displayState$ = this.lens$.pipe(
        filter(lens => !!lens.model),
        map(lens =>  this._modelProcessingProgress.update(lens)));

      this.displayState$.pipe(
        first(response => response.msg && response.msg.indexOf('Excessive resubmit detected. Finalize only') > -1),
      ).subscribe((response) => {
        this.router.navigateByUrl(`/platform/lenses/training/${this.lensId}/error/lowdocumentcount`);
      });

      // set poller
      this.lensService.load(this.lensId, 'id', false);
      this._interval = window.setInterval(() => {
        if (this.lensId > 0) {
          this.lensService.load(this.lensId, 'id', false);
        }
      }, 2500);

      // observe when a lens is finished and re route
      this.lens$.pipe(
        filter(lens => (!!lens && lens.model.isCompleted && lens.model.isFailed !== true)),
        first(lens => lens.id === this.lensId),
        delay(10 * 1000) // let the fancy graph finish...
      ).subscribe(lens => {
        clearInterval(this._interval);
        this.router.navigateByUrl(`/platform/lenses/train/${this.lensId}`);
      });

      // observe when a lens is failed
      this.lens$.pipe(
        filter(lens => (!!lens && lens.model.isFailed && !!lens.model.parentLens)),
        first(lens => lens.id === this.lensId)
      ).subscribe(lens => {
        clearInterval(this._interval);
        if (lens.model.parentLens.id) {
          this.parentLensId = lens.model.parentLens.id;
        }
      }); // end
    });
  }

  toPrevious(): void {
    this.router.navigateByUrl(`/platform/lenses/train/${this.parentLensId}`);
  }

  ngOnDestroy() {
    clearInterval(this._interval);
  }

  doRestart(action: LensActions) {
    this._modelProcessingProgress.reset();
    this.lens$.pipe(
      first()
    ).subscribe(lens => {
      this.lensService.reprocess(lens.id, action).pipe(
        first(aLens => !!aLens),
      ).subscribe(response => {
        this.router.navigateByUrl(`/platform/lenses/training/${response.id}`);
      });
    });
  }
}
