import {Injectable} from '@angular/core';
import {combineLatest, merge, Observable, of, timer} from 'rxjs';
import {TopFilterComponent} from './top-filter.component';
import {filter, map, mergeMap, switchMap, takeUntil} from 'rxjs/operators';
import {IViewBy} from './iviewby.interface';
import {BaseService} from '../../services-http/base.service';
import {ActivatedRoute, Event, NavigationEnd, Router, Event as RouterEvent} from '@angular/router';
import {IBase} from '../../types/base';

@Injectable()
export class ListFilterService {

  constructor(private router: Router, private activeRouter: ActivatedRoute) {}

  createObservableFilter<T>(getList: (allUsers: boolean) => Observable<T>,
                            topFilter: TopFilterComponent, defaultView: IViewBy, searchProps: string[] = ['name']): Observable<T> {

    const allUsers$ = merge(of(false), topFilter.onAllUsers.asObservable()).pipe(
      switchMap(allUsers => timer(0, 5 * 1000).pipe(map(() => allUsers))),
      takeUntil(this.router.events.pipe(
        filter(event => event instanceof NavigationEnd),
      )));

    // return the filtering pipeline
    const filterText$ = merge(of(''), topFilter.textFilterChange.asObservable());
    const view$ = merge(of(defaultView), topFilter.orderOptionSelected.asObservable());
    const items$ = allUsers$.pipe(mergeMap(bool => getList(bool)));

    return combineLatest(items$, filterText$, view$).pipe(
      map(this.applyFilter(searchProps))
    );
  }

  createTablePipeline<T extends IBase>(service: BaseService<T>,
                         topFilter: TopFilterComponent,
                         defaultView: IViewBy, searchProps: string[] = ['name']): Observable<T[]> {

    // Load all elements every 5 seconds
    const allUsers$ = merge(of(false), topFilter.onAllUsers.asObservable());
    allUsers$.pipe(
      switchMap(allUsers => timer(0, 5000).pipe(map(() => allUsers))),
      takeUntil(this.router.events.pipe(
        filter(event => event instanceof NavigationEnd)
      ))).subscribe(allUsers => service.loadAll(allUsers));

    // return the filtering pipeline
    const filterText$ = merge(of(''), topFilter.textFilterChange.asObservable());
    const view$ = merge(of(defaultView), topFilter.orderOptionSelected.asObservable());
    return combineLatest(service.elements, filterText$, view$).pipe(
      map(this.applyFilter(searchProps))
    );
  }

  public applyFilter = (searchProps: string[]) => ( args: any ) => {
    let [list, filterText, view] = args;

    list = list || [];

    // filter by hidden / visible
    if (!!list.length && list[0]['isSoftDeleted'] !== undefined) {
      list = list
        .filter(item => !!item)
        .filter(item => (<any>item).isSoftDeleted === view.isFilter);
    }

    // filter by search string
    filterText = filterText.trim().toLowerCase();
    if (filterText.length) {
      list = list.filter(item => {
        const str = searchProps.reduce((acc, prop) => {
          const v = this.resolve(prop, item);
          return acc + (v ? v : '');
        }, '');
        return str.toLowerCase().includes(filterText);
      });
    }

    // sort the rows
    const key = view.isFilter ? 'createdAt' : view.key;
    const comparator = (a, b) => {
      const av = this.resolve(key, a);
      const bv = this.resolve(key, b);
      let comp: number;
      if (typeof av === 'string') {
        comp = av < bv ? -1 : av > bv ? 1 : 0;
      } else {
        comp = av - bv;
      }
      return comp * (view.order === 'asc' ? 1 : -1);
    };
    list = list.sort(comparator);

    return list;
  }

  /** Dynamic nested object property access */
  private resolve(propStr: string, obj: Object) {
    return propStr.split('.').reduce((result, prop) => result ? result[prop] : undefined, obj);
  }
}
