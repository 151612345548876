<section id="loading">

  <!-- MODEL INFO -->
  <div class="row pt-5">
    <div class="offset-3 col-6 paper text-center">
      <p class="lens-name mt-5">{{ (lens$ | async)?.name }}</p>
      <p class="lens-label">{{ (lens$ | async)?.createdAt | date: 'medium' }}</p>
    </div>
  </div>

  <!-- MODEL TIMED OUT -->
  <div *ngIf="(displayState$ | async)?.status==='isTimedOut'">
    <div class="offset-2 col-8 paper text-center">
      <div class="offset-3 col-6 paper text-center">
        <div class="lens-label">Your job has been interrupted, possibly due to scheduled downtime. How would you like to reprocess?</div>
        <button class="btn btn-primary mt-30" (click)="doRestart(LensActions.APPLY)">Apply Changes</button>
        <button class="btn btn-primary mt-30 ms-8" (click)="doRestart(LensActions.FIND_TOPICS)">Find New Topics</button>
        <button class="btn btn-primary mt-30 ms-8" (click)="doRestart(LensActions.FINISH)">Finish Model</button>
      </div>
    </div>
  </div>

  <!--MODEL FATAL -->
  <div *ngIf="(displayState$ | async)?.status==='fatal'">
    <div class="offset-4 col-4 paper text-center">
      <div class="row top-border">
        <div class="col-12 failed">
          FATAL ERROR
        </div>
      </div>
      <div class="row bottom-border">
        <div class="col-12">
          <i class="material-icons mt-5 info-icon">error</i>
          <p class="lens-label font-italic">Error: {{ (displayState$ | async)?.msg }}</p>
        </div>
      </div>
    </div>
    <div class="offset-4 col-4 paper text-center mt-0">
      <span class="lens-label">For assistance, email us at </span>
      <a class="lens-label lens-label-link" href="mailto:support@pienso.com">support@pienso.com</a>
    </div>
  </div>

  <!--MODEL FAILED -->
  <div *ngIf="(displayState$ | async)?.status==='failed'">
    <div class="offset-4 col-4 paper text-center">
      <div class="row top-border">
        <div class="col-12 failed">
          MODEL FAILED
        </div>
      </div>
      <div class="row bottom-border">
        <div class="col-12">
          <i class="material-icons mt-5 info-icon">error</i>
          <p class="lens-label font-italic">Training Failed: {{ (displayState$ | async)?.msg }}</p>
          <p class="lens-label font-italic">Updated At: {{ (displayState$ | async)?.updatedAt | date:'medium' }}</p>
          <div *ngIf="parentLensId" class="mt-5 mb-5">
            <button class="btn-back" (click)="toPrevious()">BACK TO PREVIOUS MODEL</button>
          </div>
        </div>
      </div>
    </div>
    <div class="offset-4 col-4 paper text-center mt-0">
      <span class="lens-label">For assistance, email us at </span>
      <a class="lens-label lens-label-link" href="mailto:support@pienso.com">support@pienso.com</a>
    </div>
  </div>

  <!-- MODEL PROCESSING / COMPLETED -->
  <div class="row">

    <div class="col-12 text-center">
      <span class="d-inline-block lens-label" [innerHTML]="(displayState$ | async)?.actionWithStepLabel ||''"></span>
    </div>

    <div class="col-12 paper text-center" [hidden]="(displayState$ | async)?.status==='fatal' || (displayState$ | async)?.status==='failed'">
      <ngb-progressbar
        class="p-bar"
        type="info"
        [striped]='true'
        [value]="(displayState$ | async)?.completionByWhole" [animated]="true">
      </ngb-progressbar>
      <span class="lens-label d-inline-block ms-3">
        {{ (displayState$ | async)?.completionByWhole }} %
      </span>
    </div>
  </div>

  <!-- HAMILTONIAN CHART -->
  <section *ngIf="(displayState$ | async)?.completionByWhole >= 0.1">
    <div class="row pt-3" [hidden]="(displayState$ | async)?.status==='fatal' ||
                                    (displayState$ | async)?.status==='failed' ||
                                    (displayState$ | async)?.status==='queued'">
      <div class="col-12 text-center">
        <app-hamiltonian-chart [completion]="(displayState$ | async)?.completionByWhole" [lensId]="lensId"></app-hamiltonian-chart>
      </div>
    </div>
  </section>

</section>
