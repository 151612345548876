<div class="top-filter text-right d-inline-block">
  <!-- <div class="form-check"> -->
    <input type="checkbox" class="form-check-input align-baseline" (change)="onAllUsers.emit(allUsers)" id="userChk" [(ngModel)]="allUsers">
    <label for="userChk" class="form-check-label ms-1 me-5">ALL USERS</label>  
  <!-- </div> -->
  <div *ngIf="hasTextFilter" class="d-inline-block me-3">
    <app-search-input (searchText)="updateTextFilter($event)"></app-search-input>
  </div>
  <div class="pe-0 ps-0 filter-wrapper text-center position-relative d-inline-block">
    <label class="text-uppercase mb-0 me-2">View:</label>
    <app-filter-select class="filter"
                       *ngIf="hasOrderFilter"
                       [options]="selectOptions"
                       (optionSelected)="handleOrderOptionSelected($event)">
    </app-filter-select>
  </div>
</div>
