import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LensLoaderComponent} from './lens-loader.component';
import {TrainModelErrorComponent} from './train-model-error/train-model-error.component';
import {HamiltonianChartComponent} from './hamiltoninan-chart/hamiltonian-chart.component';
import { ModifierReviewModule } from '../modifier-review/modifier-review.module';
import {NgbProgressbarModule} from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  imports: [
    CommonModule,
    ModifierReviewModule,
    NgbProgressbarModule
  ],
  declarations: [
    LensLoaderComponent,
    HamiltonianChartComponent,
    TrainModelErrorComponent
  ],
  exports: [
    LensLoaderComponent
  ]
})
export class LensLoaderModule {}
