import {Component} from '@angular/core';
import {KeycloakService} from 'keycloak-angular';


@Component({
  selector: 'app-feed-welcome-card',
  template: `
    <app-feed-card [title]="'Welcome'">
      <ng-container class="feed-card-body">
        <img class="d-block" src="/core/assets/images/welcome.svg" style="width: 100%; border-radius: 4px; margin-bottom: 10px">
        <p class="p-feedcard-secondary text-left">Welcome to Pienso, a machine learning platform that lets you lend your expertise to AI&mdash;because humans and algorithms work
          better together. Whether you're looking to parse every word in a library's-worth of scholarly journals or assess social sentiment
          from a torrent of real-time tweets, Pienso makes it easy to extract insights from your unstructured data.</p>
        <p class="p-feedcard-secondary text-left">The Pienso User Manual provides a detailed walk-through of the entire application, and is the perfect resource for new users.
          But if you're already familiar with Pienso or prefer to dive right in, visit Data Ingest to begin uploading your first training
          data.</p>
      </ng-container>
      <ng-container class="feed-card-footer">
        <button type="button" class="btn btn-primary" (click)="navDocs()">User Manual</button>
        <button type="button" class="btn btn-primary" (click)="navIngest()">Upload Data</button>
      </ng-container>
    </app-feed-card>
  `
})
export class WelcomeCardComponent {

  profile: Keycloak.KeycloakProfile;

  constructor(private kc: KeycloakService) {
    kc.loadUserProfile().then((aProfile => this.profile = aProfile));
  }

  navDocs() {
    window.open(
      'https://pienso.atlassian.net/wiki/spaces/usermanual/overview',
      '_blank'
    );
  }

  navIngest() {
    window.location.href = window.location.origin + '/ingest';
  }

}
