<div class="modal-header">
  <div class="modal-title">EDIT TAGS: {{model.name}}</div>
</div>
<div class="modal-body">
  <form>
    <mat-form-field>
      <mat-label>Enter tag names</mat-label>
      <mat-chip-grid #tagGrid aria-label="Tag selection">
        <mat-chip-row *ngFor="let tag of tags" (removed)="remove(tag)">
          {{tag}}
          <button matChipRemove [attr.aria-label]="'remove ' + tag">
            <fa-icon [icon]="removeIcon"></fa-icon>
          </button>
        </mat-chip-row>
      </mat-chip-grid>
      <input placeholder="New Tag..." #tagInput
        [formControl]="tagCtrl"
        [matChipInputFor]="tagGrid" [matAutocomplete]="auto"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        (matChipInputTokenEnd)="add($event)"/>
      <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
        <mat-option *ngFor="let tag of filteredTags | async" [value]="tag">
          {{tag}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </form>
  <!-- <tag-input name="tags" [addOnPaste]="true"
             [pasteSplitPattern]="','"
             [(ngModel)]='tags'
             [editable]="true"
             [modelAsStrings]="true"
             [placeholder]="'Add tag'"
             [secondaryPlaceholder]="'Enter tag name'"
             [trimTags]="true">
    <tag-input-dropdown [showDropdownIfEmpty]="true"
                        [autocompleteItems]="allTags"
                        [zIndex]="2000"></tag-input-dropdown>
  </tag-input> -->
</div>
<div class="modal-footer justify-content-center">
  <button class="btn btn-primary" (click)="doSave()">SAVE</button>
  <button class="btn btn-outline" (click)="doCancel()" ngbAutofocus>CANCEL</button>
</div>
